import React from "react"
import { graphql } from 'gatsby'

//components
import Layout from "/src/components/Layout"
import Hero from "/src/components/SellBuyPage/Hero"
import WhyCBS from "/src/components/SellBuyPage/WhyCBS"
import Services from "/src/components/SellBuyPage/Services"
import MultiLayer from "/src/components/SellBuyPage/MultiLayer"
import MoreInformation from "/src/components/SellBuyPage/MoreInformation"
import QuickLiquidation from "/src/components/SellBuyPage/QuickLiquidation"
import ReadyToLiquidate from "/src/components/SellBuyPage/ReadyToLiquidate"
import QuickAdministration from "/src/components/SellBuyPage/QuickAdministration"
import CardsSection from "/src/components/SellBuyPage/CardsSection"
import GoldBorder from "/src/components/GoldBorder"
import SEO from "/src/components/seo"
// import * as styles from './avveckla-aktiebolag.module.scss'

export const pageQuery = graphql`query SellQuery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    html
    frontmatter {
      heroSlogan
      heroSloganMobile
      heroTitle
      heroParagraph
      heroMainButtonText
      heroMainButtonUrl
      heroButtonRightTextMobile
      heroImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      heroCursiveTextImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      heroTabletLogoLeft {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      heroTabletLogoRight {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      cardsTitle
      cardsParagraph
      cardsImageLeft {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      cardsImageRight {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      cardsCardItems {
        title
      }
      cardsButtonText
      cardsButtonUrl
      testimonialsQuote
      testimonialsImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      testimonialsLogo {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      testimonialsLogoUrl
      testimonialsList {
        text
        author
      }
      whyCbsTitle
      whyCbsSubtitle
      whyCbsCardsContentList {
        title
        content
        icon {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      servicesTitle
      servicesVideoThumbnail {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      servicesBottomText
      servicesServicesList {
        content
      }
      servicesButtonText
      servicesButtonUrl
      servicesImageNextToButton {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      servicesTextNextToButton
      servicesVideoId
      quickLiquidationImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      quickLiquidationTitle
      quickLiquidationFirstParagraph
      quickLiquidationGreenText
      quickLiquidationThirdParagraph
      quickLiquidationFourthParagraph
      quickLiquidationOrangeText
      quickLiquidationGuarantees {
        guarantee
      }
      quickLiquidationQuote
      quickAdministrationTitle
      quickAdministrationSubtitle
      quickAdministrationFirstParagraph
      quickAdministrationLinkText
      quickAdministrationLinkUrl
      quickAdministrationSecondParagraph
      quickAdministrationButtonText
      quickAdministrationButtonUrl
      quickAdministrationImageRightOfButton {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      quickAdministrationBelowButton
      quickAdministrationImageRight {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      moreInfoTitle
      moreInfoSubtitle
      moreInfoInfos {
        info
        link
      }
      readyToLiquidateTitle
      readyToLiquidateSubtitle
      readyToLiquidateImages {
        employee {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      readyToLiquidateBottomTitle
      readyToLiquidateBottomSubtitle
      readyToLiquidateButtonText
      readyToLiquidateButtonUrl
    }
  }
}
`

const SellPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark

  return (
    <Layout slogan={false}>
      <SEO
        title="Avveckla aktiebolag - Sälj aktiebolag till oss för likvidation"
        description="Sälj ditt aktiebolag till Citadellet bolagsservice AB så likviderar vi bolaget åt dig. Snabbavveckling - Snabbt, enkelt och tryggt!"
      />

      <Hero
        sloganMobile={frontmatter.heroSloganMobile}
        slogan={frontmatter.heroSlogan}
        title={frontmatter.heroTitle}
        paragraph={frontmatter.heroParagraph}
        heroMainButtonText={frontmatter.heroMainButtonText}
        heroMainButtonUrl={frontmatter.heroMainButtonUrl}
        heroButtonRightTextMobile={frontmatter.heroButtonRightTextMobile}
        img={frontmatter.heroImage.childImageSharp.fluid.src}
        heroCursiveTextImage={frontmatter.heroCursiveTextImage.childImageSharp.fluid.src}
        heroTabletLogoLeft={frontmatter.heroTabletLogoLeft.childImageSharp.fluid.src}
        heroTabletLogoRight={frontmatter.heroTabletLogoRight.childImageSharp.fluid.src}
      />

      <GoldBorder />

      <CardsSection
        title={frontmatter.cardsTitle}
        paragraph={frontmatter.cardsParagraph}
        imageLeft={frontmatter.cardsImageLeft.childImageSharp.fluid.src}
        imageRight={frontmatter.cardsImageRight.childImageSharp.fluid.src}
        cards={frontmatter.cardsCardItems}
        cardsButtonText={frontmatter.cardsButtonText}
        cardsButtonUrl={frontmatter.cardsButtonUrl}
      />

      <MultiLayer
        testimonialsQuote={frontmatter.testimonialsQuote}
        testimonialsImage={frontmatter.testimonialsImage.childImageSharp.fluid.src}
        testimonialsLogo={frontmatter.testimonialsLogo.childImageSharp.fluid.src}
        testimonialsLogoUrl={frontmatter.testimonialsLogoUrl}
        testimonialsList={frontmatter.testimonialsList}
      />

      <GoldBorder />

      <WhyCBS
        whyCbsTitle={frontmatter.whyCbsTitle}
        whyCbsSubtitle={frontmatter.whyCbsSubtitle}
        whyCbsCardsContentList={frontmatter.whyCbsCardsContentList}
      />

      <Services
        servicesTitle={frontmatter.servicesTitle}
        servicesVideoThumbnail={frontmatter.servicesVideoThumbnail.childImageSharp.fluid.src}
        servicesBottomText={frontmatter.servicesBottomText}
        servicesServicesList={frontmatter.servicesServicesList}
        servicesButtonText={frontmatter.servicesButtonText}
        servicesButtonUrl={frontmatter.servicesButtonUrl}
        servicesImageNextToButton={frontmatter.servicesImageNextToButton.childImageSharp.fluid.src}
        servicesTextNextToButton={frontmatter.servicesTextNextToButton}
        videoId={frontmatter.servicesVideoId}
      />

      <QuickLiquidation
        quickLiquidationImage={frontmatter.quickLiquidationImage.childImageSharp.fluid.src}
        quickLiquidationTitle={frontmatter.quickLiquidationTitle}
        quickLiquidationFirstParagraph={frontmatter.quickLiquidationFirstParagraph}
        quickLiquidationGreenText={frontmatter.quickLiquidationGreenText}
        quickLiquidationThirdParagraph={frontmatter.quickLiquidationThirdParagraph}
        quickLiquidationFourthParagraph={frontmatter.quickLiquidationFourthParagraph}
        quickLiquidationOrangeText={frontmatter.quickLiquidationOrangeText}
        quickLiquidationQuote={frontmatter.quickLiquidationQuote}
        quickLiquidationGuarantees={frontmatter.quickLiquidationGuarantees}
      />

    <QuickAdministration
        isBuyPage={false}
        quickAdministrationTitle={frontmatter.quickAdministrationTitle}
        quickAdministrationSubtitle={frontmatter.quickAdministrationSubtitle}
        quickAdministrationFirstParagraph={frontmatter.quickAdministrationFirstParagraph}
        quickAdministrationLinkText={frontmatter.quickAdministrationLinkText}
        quickAdministrationLinkUrl={frontmatter.quickAdministrationLinkUrl}
        quickAdministrationSecondParagraph={frontmatter.quickAdministrationSecondParagraph}
        quickAdministrationButtonText={frontmatter.quickAdministrationButtonText}
        quickAdministrationButtonUrl={frontmatter.quickAdministrationButtonUrl}
        quickAdministrationBelowButton={frontmatter.quickAdministrationBelowButton}
        quickAdministrationImageRightOfButton={frontmatter.quickAdministrationImageRightOfButton.childImageSharp.fluid.src}
        quickAdministrationImageRight={frontmatter.quickAdministrationImageRight.childImageSharp.fluid.src}
      />

      <MoreInformation
        moreInfoTitle={frontmatter.moreInfoTitle}
        moreInfoSubtitle={frontmatter.moreInfoSubtitle}
        moreInfoInfos={frontmatter.moreInfoInfos}
      />

      <ReadyToLiquidate
        readyToLiquidateTitle={frontmatter.readyToLiquidateTitle}
        readyToLiquidateSubtitle={frontmatter.readyToLiquidateSubtitle}
        readyToLiquidateImages={frontmatter.readyToLiquidateImages}
        readyToLiquidateBottomTitle={frontmatter.readyToLiquidateBottomTitle}
        readyToLiquidateBottomSubtitle={frontmatter.readyToLiquidateBottomSubtitle}
        readyToLiquidateButtonText={frontmatter.readyToLiquidateButtonText}
        readyToLiquidateButtonUrl={frontmatter.readyToLiquidateButtonUrl}
      />
    </Layout>
  )
}

export default SellPage
